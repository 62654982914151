import calc_bg from "../images/calc_bg.svg";

export default function ResultHeader({title}) {
  return (
    <header className="result-header">   
      <div className="result-header--bg">
        <div className="result-header--image">
          <img src={calc_bg} height={150}/>
        </div>
        <div className="result-header--layout">
          <div className="result-header--text">
            {title}
          </div>
          <div className="result-header--text">
            HIV抗逆转录病毒药物费用一览
          </div>
        </div>
      </div>
      <div className="placeholder"></div>
    </header>
  )
}