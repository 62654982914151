import { CaretRightOutlined } from "@ant-design/icons";

export default function InstructHeader({ text1, text2, image }) {
  return (
    <header className="calc-header">
      <div className="calc-header--bg">
        <div className="calc-header--text">
          <span>{text1}</span>
          <CaretRightOutlined />
          <span>{text2}</span>
        </div>
        <div className="instruct-header--image">
          <img src={image} height="80"/>
        </div>
      </div>
    </header>
  )
}