let pillData = [
  {
    "Province": "广东",
    "City": "广州",
    "Hospital": "广州市第八人民医院",
    "Type": "医保",
    "Pill": "艾诺韦林片/艾邦德",
    "Amount": 1,
    "Price": 514.8,
    "Rate1": 0.1,
    "Rate2": 0.15,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 800,
    "Ceiling2": 640
  },
  {
    "Province": "广东",
    "City": "广州",
    "Hospital": "广州市第八人民医院",
    "Type": "医保",
    "Pill": "艾考恩丙替片/捷扶康",
    "Amount": 1,
    "Price": 1290,
    "Rate1": 0.1,
    "Rate2": 0.15,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 800,
    "Ceiling2": 640
  },
  {
    "Province": "广东",
    "City": "广州",
    "Hospital": "广州市第八人民医院",
    "Type": "医保",
    "Pill": "比克恩丙诺片/必妥维",
    "Amount": 1,
    "Price": 1125,
    "Rate1": 0.1,
    "Rate2": 0.15,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 800,
    "Ceiling2": 640
  },
  {
    "Province": "广东",
    "City": "广州",
    "Hospital": "广州市第八人民医院",
    "Type": "自费",
    "Pill": "多替阿巴拉米片/绥美凯",
    "Amount": 1,
    "Price": 2880,
    "Rate1": 1,
    "Rate2": 1,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 800,
    "Ceiling2": 640
  },
  {
    "Province": "广东",
    "City": "广州",
    "Hospital": "广州市第八人民医院",
    "Type": "医保",
    "Pill": "拉米夫定多替拉韦片/多伟托",
    "Amount": 1,
    "Price": 885,
    "Rate1": 0.1,
    "Rate2": 0.15,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 800,
    "Ceiling2": 640
  },
  {
    "Province": "广东",
    "City": "广州",
    "Hospital": "广州市第八人民医院",
    "Type": "自费",
    "Pill": "多拉米替片/德思卓",
    "Amount": 1,
    "Price": 1390,
    "Rate1": 1,
    "Rate2": 1,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 800,
    "Ceiling2": 640
  },
  {
    "Province": "广东",
    "City": "广州",
    "Hospital": "广州市第八人民医院",
    "Type": "自费",
    "Pill": "利匹韦林片/恩临",
    "Amount": 1,
    "Price": 1200,
    "Rate1": 1,
    "Rate2": 1,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 800,
    "Ceiling2": 640
  },
  {
    "Province": "广东",
    "City": "广州",
    "Hospital": "广州市第八人民医院",
    "Type": "自费",
    "Pill": "多替拉韦钠片/特威凯",
    "Amount": 1,
    "Price": 980,
    "Rate1": 1,
    "Rate2": 1,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 800,
    "Ceiling2": 640
  },
  {
    "Province": "广东",
    "City": "广州",
    "Hospital": "广州市第八人民医院",
    "Type": "自费",
    "Pill": "拉替拉韦钾片/艾生特",
    "Amount": 1,
    "Price": 1905,
    "Rate1": 1,
    "Rate2": 1,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 800,
    "Ceiling2": 640
  },
  {
    "Province": "广东",
    "City": "广州",
    "Hospital": "广州市第八人民医院",
    "Type": "自费",
    "Pill": "达芦那韦考比司他片/普泽力",
    "Amount": 1,
    "Price": 1500,
    "Rate1": 1,
    "Rate2": 1,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 800,
    "Ceiling2": 640
  },
  {
    "Province": "广东",
    "City": "广州",
    "Hospital": "广州市第八人民医院",
    "Type": "自费",
    "Pill": "多拉韦林片/沛卓",
    "Amount": 1,
    "Price": 980,
    "Rate1": 1,
    "Rate2": 1,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 800,
    "Ceiling2": 640
  },
  {
    "Province": "广东",
    "City": "广州",
    "Hospital": "广州市第八人民医院",
    "Type": "国家免费",
    "Pill": "依非韦伦片/施多宁",
    "Amount": 1,
    "Price": 515.4,
    "Rate1": 0,
    "Rate2": 0,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 800,
    "Ceiling2": 640
  },
  {
    "Province": "广东",
    "City": "广州",
    "Hospital": "广州市第八人民医院",
    "Type": "国家免费",
    "Pill": "洛匹那韦利托那韦片/克力芝",
    "Amount": 1,
    "Price": 615,
    "Rate1": 0,
    "Rate2": 0,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 800,
    "Ceiling2": 640
  },
  {
    "Province": "湖北",
    "City": "武汉",
    "Hospital": "武汉市金银潭医院",
    "Type": "医保",
    "Pill": "艾诺韦林片/艾邦德",
    "Amount": 1,
    "Price": 514.8,
    "Rate1": 0.226,
    "Rate2": 0.46,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 100000000,
    "Ceiling2": 100000000
  },
  {
    "Province": "湖北",
    "City": "武汉",
    "Hospital": "武汉市金银潭医院",
    "Type": "医保",
    "Pill": "艾考恩丙替片/捷扶康",
    "Amount": 1,
    "Price": 1026,
    "Rate1": 0.226,
    "Rate2": 0.46,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 100000000,
    "Ceiling2": 100000000
  },
  {
    "Province": "湖北",
    "City": "武汉",
    "Hospital": "武汉市金银潭医院",
    "Type": "医保",
    "Pill": "比克恩丙诺片/必妥维",
    "Amount": 1,
    "Price": 1125,
    "Rate1": 0.226,
    "Rate2": 0.46,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 100000000,
    "Ceiling2": 100000000
  },
  {
    "Province": "湖北",
    "City": "武汉",
    "Hospital": "武汉市金银潭医院",
    "Type": "自费",
    "Pill": "多替阿巴拉米片/绥美凯",
    "Amount": 1,
    "Price": 2880,
    "Rate1": 1,
    "Rate2": 1,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 100000000,
    "Ceiling2": 100000000
  },
  {
    "Province": "湖北",
    "City": "武汉",
    "Hospital": "武汉市金银潭医院",
    "Type": "医保",
    "Pill": "拉米夫定多替拉韦片/多伟托",
    "Amount": 1,
    "Price": 885,
    "Rate1": 0.226,
    "Rate2": 0.46,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 100000000,
    "Ceiling2": 100000000
  },
  {
    "Province": "湖北",
    "City": "武汉",
    "Hospital": "武汉市金银潭医院",
    "Type": "自费",
    "Pill": "多拉米替片/德思卓",
    "Amount": 1,
    "Price": 1390,
    "Rate1": 1,
    "Rate2": 1,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 100000000,
    "Ceiling2": 100000000
  },
  {
    "Province": "湖北",
    "City": "武汉",
    "Hospital": "武汉市金银潭医院",
    "Type": "自费",
    "Pill": "利匹韦林片/恩临",
    "Amount": 1,
    "Price": 1200,
    "Rate1": 1,
    "Rate2": 1,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 100000000,
    "Ceiling2": 100000000
  },
  {
    "Province": "湖北",
    "City": "武汉",
    "Hospital": "武汉市金银潭医院",
    "Type": "自费",
    "Pill": "多替拉韦钠片/特威凯",
    "Amount": 1,
    "Price": 885,
    "Rate1": 1,
    "Rate2": 1,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 100000000,
    "Ceiling2": 100000000
  },
  {
    "Province": "湖北",
    "City": "武汉",
    "Hospital": "武汉市金银潭医院",
    "Type": "自费",
    "Pill": "拉替拉韦钾片/艾生特",
    "Amount": 1,
    "Price": 1980,
    "Rate1": 1,
    "Rate2": 1,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 100000000,
    "Ceiling2": 100000000
  },
  {
    "Province": "湖北",
    "City": "武汉",
    "Hospital": "武汉市金银潭医院",
    "Type": "自费",
    "Pill": "达芦那韦考比司他片/普泽力",
    "Amount": 1,
    "Price": 1550,
    "Rate1": 1,
    "Rate2": 1,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 100000000,
    "Ceiling2": 100000000
  },
  {
    "Province": "湖北",
    "City": "武汉",
    "Hospital": "武汉市金银潭医院",
    "Type": "自费",
    "Pill": "多拉韦林片/沛卓",
    "Amount": 1,
    "Price": 980,
    "Rate1": 1,
    "Rate2": 1,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 100000000,
    "Ceiling2": 100000000
  },
  {
    "Province": "湖北",
    "City": "武汉",
    "Hospital": "武汉市金银潭医院",
    "Type": "国家免费",
    "Pill": "依非韦伦片/施多宁",
    "Amount": 1,
    "Price": 515.4,
    "Rate1": 0,
    "Rate2": 0,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 100000000,
    "Ceiling2": 100000000
  },
  {
    "Province": "湖北",
    "City": "武汉",
    "Hospital": "武汉市金银潭医院",
    "Type": "国家免费",
    "Pill": "洛匹那韦利托那韦片/克力芝",
    "Amount": 1,
    "Price": 615,
    "Rate1": 0,
    "Rate2": 0,
    "Deductible1": 0,
    "Deductible2": 0,
    "Ceiling1": 100000000,
    "Ceiling2": 100000000
  }
]

export function getPillData() {
  return pillData;
}
