export default function ResultBar({ pillInfo }) {
  return (
    <div className="flex-container table-row">
      <div className="flex-item-4 flex-item--name">{pillInfo.Pill}</div>
      <div className="flex-item-2">
        <div className="row1">月度</div>
        <div className="row2">年度</div>
      </div>
      <div className="flex-item-3">
        <div className="row1">{pillInfo.insurancePay.toFixed(2)}</div>
        <div className="row2">{((pillInfo.insurancePay)*12).toFixed(2)}</div>
      </div>
      <div className="flex-item-3">
        <div className="row1">{pillInfo.coPay.toFixed(2)}</div>
        <div className="row2">{((pillInfo.coPay)*12).toFixed(2)}</div>
      </div>
    </div>
  )
}