import { useSearchParams, Link } from "react-router-dom";
import Footer from "../components/footer";
import ResultHeader from "../components/result_header";
import { getPillData } from "../data/pillData";

import ResultBar from "../components/result_bar";

export default function CalculateResult() {
  const [searchParams] = useSearchParams();
  const city = searchParams.get("city");
  const facility = searchParams.get("facility");
  const pills = JSON.parse(searchParams.get("pills"));
  const type = searchParams.get("type");

  let pillData = getPillData();
  pillData = pillData.filter(item => (
    item.City === city && item.Hospital === facility
  ));

  pillData = pillData.filter(item => pills.includes(item.Pill));

  let totalResult = {
    Pill: "总计",
    insurancePay: 0,
    coPay: 0,
  };

  pillData.map(pillInfo => {
    const cost = pillInfo.Amount * pillInfo.Price;
    let rate, deductible, ceiling;

    if (type === "城镇职工医保") {
      rate = pillInfo.Rate1;
      deductible = pillInfo.Deductible1;
      ceiling = pillInfo.Ceiling1;
    } else {
      rate = pillInfo.Rate2;
      deductible = pillInfo.Deductible2;
      ceiling = pillInfo.Ceiling2;
    }

    // 自费药物
    if (pillInfo.Type === "自费") {
      pillInfo.insurancePay = 0;
      pillInfo.coPay = cost;
    } else

    // 国家免费
    if (pillInfo.Type === "国家免费") {
      pillInfo.insurancePay = cost;
      pillInfo.coPay = 0;
    } else

    // 若有起付线且低于统筹支付标准
    if (deductible !== 0 && cost <= ceiling/(1-rate)) {
      // =（（每月药费-起付线）*自付比例）
      pillInfo.coPay = (cost-deductible)*rate+deductible;
      pillInfo.insurancePay = cost - pillInfo.coPay;
    } else

    // 若无起付线且低于统筹支付标准
    if (deductible === 0 && cost <= ceiling/(1-rate)) {
      // =（每月药费*自付比例）
      pillInfo.coPay = cost*rate;
      pillInfo.insurancePay = cost - pillInfo.coPay;
    } else

    // 若无起付线且高于统筹支付标准
    if (deductible === 0 && cost > ceiling/(1-rate)) {
      // =（每月药费-当月统筹封顶线/(1-自付比例)）+统筹封顶线/(1-自费比例)*自费比例
      pillInfo.coPay = (cost-ceiling/(1-rate)) + ceiling/(1-rate)*rate;
      pillInfo.insurancePay = cost - pillInfo.coPay;
    } else

    // 若有起付线且高于统筹支付标准
    if (deductible !== 0 && cost-deductible > ceiling/(1-rate)) {
      // =（每月药费-当月统筹封顶线/(1-自付比例)）+统筹封顶线/(1-自费比例)*自费比例
      pillInfo.coPay = (cost-ceiling/(1-rate)) + ceiling/(1-rate)*rate;
      pillInfo.insurancePay = cost - pillInfo.coPay;
    }

    totalResult.insurancePay += pillInfo.insurancePay;
    totalResult.coPay += pillInfo.coPay;
  });

  pillData.push(totalResult);

  return (
    <div className="container">
      <ResultHeader title={facility} />
      <div className="curve-container">
        <div className="result-curve">
          {/* <div className="flex-container"> */}
            <div className="flex-item-4 table-header table-header--name">药品名</div>
            <div className="flex-item-2 table-header">周期</div>
            <div className="flex-item-3 table-header">医保报销费用<br />(元)</div>
            <div className="flex-item-3 table-header">自付费用<br />(元)</div>
          {/* </div> */}
        </div>
      </div>

      <main>
        <div>
          {pillData.map(pillInfo => (
            <ResultBar key={pillInfo.Pill} pillInfo={pillInfo} />
          ))}
        </div>

        <div className="footer-text-blue">计算结果仅供参考，以当地实际医保政策为准。</div>
        <div className="submit-field">
          <Link to="/calculator">
            <div className="button-text">重新计算</div>
          </Link>
        </div>
        <div className="footer-text-h">参考资料：</div>
        <div className="footer-text-p">1.《国家基本医疗保险、工伤保险和生育保险药品目录（2021年）》</div>
        <div className="footer-text-p">2.《广州市社会医疗保险门诊特定病种药品目录、诊疗项目目录（2019年版）》</div>
        <div className="footer-text-p">3. 相关计算逻辑仅考虑门诊报销情况</div>
      </main>
      <Footer active="calculator"/>
    </div>
  );
}