import { Picker } from "antd-mobile";
import { useState } from "react";
import { DownCircleFilled } from "@ant-design/icons";

export default function FacilityPicker({ options, changeHandler }) {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(["请选择医疗机构"]);

  return (
    <>
      <div className="form-label">
        <div>选择医疗机构</div>
        <DownCircleFilled
          style={{fontSize: "24px"}}
          onClick={() => {
            setVisible(true)
          }}
        />
      </div>
      <div className="form-field" onClick={() => {
          setVisible(true)
        }}
      >{value}</div>
      <Picker
        columns={options}
        visible={visible}
        title="请选择医疗机构"
        onClose={() => {
          setVisible(false)
        }}
        onConfirm={v => {
          if (v[0] !== null) {
            setValue(v);
            changeHandler(v[0]);
          }
        }}
      />
    </>
  )
}