let instruction = [
  {
    "Id": 1,
    "Name": "艾诺韦林片/艾邦德",
    "ProductName": "艾邦德",
    "EnglishName": "Ainuovirine Tables",
    "Size": "75mg",
    "Type": "片剂",
    "Unit": "瓶",
    "Manufacture": "江苏艾迪药业股份有限公司",
    "Insurance": "医保",
    "Price": "514.8元/瓶（医保报销前）",
    "Method": "口服",
    "Dose": "1次/日,2片/次（详见药品说明书）",
    "Association": "应与其他抗逆转录病毒药物联合使用",
    "WithFood": "空腹服用（详见药品说明书）"
  },
  {
    "Id": 2,
    "Name": "艾考恩丙替片/捷扶康",
    "ProductName": "捷扶康",
    "EnglishName": "Elvitegravir,Cobicistat,Emtricitabine and Tenofovir Alafenamide Fumarate Tablets",
    "Size": "150mg（艾维雷韦）:150mg（考比司他）:200mg（恩曲他滨）:10mg（丙酚替诺福韦）",
    "Type": "片剂",
    "Unit": "瓶",
    "Manufacture": "GileadSciencesIrelandUC",
    "Insurance": "医保",
    "Price": "1026元/瓶（医保报销前）",
    "Method": "口服",
    "Dose": "1次/日,1片/次（详见药品说明书）",
    "Association": "",
    "WithFood": "随食物服用（详见药品说明书）"
  },
  {
    "Id": 3,
    "Name": "比克恩丙诺片/必妥维",
    "ProductName": "必妥维",
    "EnglishName": "Bictegravir Sodium, Emtricitabine and Tenofovir Alafenamide Fumarate Tablets",
    "Size": "50mg（比克替拉韦）:200mg（恩曲他滨）:25mg（丙酚替诺福韦）",
    "Type": "片剂",
    "Unit": "瓶",
    "Manufacture": "GileadSciencesIrelandUC",
    "Insurance": "医保",
    "Price": "1125元/瓶（医保报销前）",
    "Method": "口服",
    "Dose": "1次/日,1片/次（详见药品说明书）",
    "Association": "",
    "WithFood": "可与或不与食物同服（详见药品说明书）"
  },
  {
    "Id": 4,
    "Name": "拉米夫定多替拉韦片/多伟托",
    "ProductName": "多伟托",
    "EnglishName": "Lamivudine and Dolutegravir Sodium Tablets",
    "Size": "50mg（多替拉韦）:300mg（拉米夫定）",
    "Type": "片剂",
    "Unit": "瓶",
    "Manufacture": "GlaxoSmithKlineLLC",
    "Insurance": "医保",
    "Price": "885元/瓶（医保报销前）",
    "Method": "口服",
    "Dose": "1次/日,1片/次（详见药品说明书）",
    "Association": "",
    "WithFood": "可与或不与食物同服（详见药品说明书）"
  },
  {
    "Id": 5,
    "Name": "多替阿巴拉米片/绥美凯",
    "ProductName": "绥美凯",
    "EnglishName": "Dolutegravir Sodium, Abacavir Sulfate and Lamivudine Tablets",
    "Size": "50mg（多替拉韦）:600mg（阿巴卡韦:300mg（拉米夫定）",
    "Type": "片剂",
    "Unit": "瓶",
    "Manufacture": "ViiVHealthcareUKLimitedGlaxoOperationsUKLtd（tradingasGlaxoWellcomeOperations）",
    "Insurance": "自费",
    "Price": "2880元/瓶",
    "Method": "口服",
    "Dose": "1次/日,1片/次（详见药品说明书）",
    "Association": "",
    "WithFood": "可与或不与食物同服（详见药品说明书）"
  },
  {
    "Id": 6,
    "Name": "多拉米替片/德思卓",
    "ProductName": "德思卓",
    "EnglishName": "Doravirine lamivudine and tenofovir disoproxil fumarate Tablets",
    "Size": "100mg（多拉韦林）:300mg（拉米夫定）:300mg（富马酸替诺福韦二吡呋酯）",
    "Type": "片剂",
    "Unit": "瓶",
    "Manufacture": "MSDInternationalGmbH",
    "Insurance": "自费",
    "Price": "1390元/瓶",
    "Method": "口服",
    "Dose": "1次/日,1片/次（详见药品说明书）",
    "Association": "",
    "WithFood": "可与或不与食物同服（详见药品说明书）"
  },
  {
    "Id": 7,
    "Name": "利匹韦林片/恩临",
    "ProductName": "恩临",
    "EnglishName": "Rilpivirine Tablets",
    "Size": "25mg",
    "Type": "片剂",
    "Unit": "瓶",
    "Manufacture": "Janssen-CilagInternationalN.V.Janssen-CilagSpA",
    "Insurance": "自费",
    "Price": "1200元/盒",
    "Method": "口服",
    "Dose": "1次/日,1片/次（详见药品说明书）",
    "Association": "应与其他抗逆转录病毒药物联合使用",
    "WithFood": "随食物服用（详见药品说明书）"
  },
  {
    "Id": 8,
    "Name": "多替拉韦钠片/特威凯",
    "ProductName": "特威凯",
    "EnglishName": "Dolutegravir Sodium Tablets",
    "Size": "50mg",
    "Type": "片剂",
    "Unit": "瓶",
    "Manufacture": "ViiVHealthcareUKLimitedGlaxoOperationsUKLtd（tradingasGlaxoWellcomeOperations）",
    "Insurance": "自费",
    "Price": "885元/瓶",
    "Method": "口服",
    "Dose": "1次/日,1片/次（详见药品说明书）",
    "Association": "",
    "WithFood": "餐前或餐后服用均可（详见药品说明书）"
  },
  {
    "Id": 9,
    "Name": "拉替拉韦钾片/艾生特",
    "ProductName": "艾生特",
    "EnglishName": "Raltegravir Potassium Tablets",
    "Size": "400mg",
    "Type": "薄膜衣片",
    "Unit": "盒",
    "Manufacture": "MSDPharma（Singapore）Pte.Ltd.MSDInternationalGmbH（SingaporeBranch）",
    "Insurance": "自费",
    "Price": "1905元/盒",
    "Method": "口服",
    "Dose": "2次/日，1片/次（详见药品说明书）",
    "Association": "应与其他抗逆转录病毒药物联合使用",
    "WithFood": "餐前或餐后服用均可（详见药品说明书）"
  },
  {
    "Id": 10,
    "Name": "达芦那韦考比司他片/普泽力",
    "ProductName": "普泽力",
    "EnglishName": "Darunavir and Cobicistat Tablets",
    "Size": "800mg（达芦那韦）:150mg（考比司他）",
    "Type": "片剂",
    "Unit": "瓶",
    "Manufacture": "Janssen-CilagInternationalN.V.JanssenOrtho,LLC",
    "Insurance": "自费",
    "Price": "1500元/盒",
    "Method": "口服",
    "Dose": "1次/日，1片/次（详见药品说明书）",
    "Association": "应与其他抗逆转录病毒药物联合使用",
    "WithFood": "须随餐同服（详见药品说明书）"
  },
  {
    "Id": 11,
    "Name": "多拉韦林片/沛卓",
    "ProductName": "沛卓",
    "EnglishName": "Doravirine Tablets",
    "Size": "100mg",
    "Type": "片剂",
    "Unit": "瓶",
    "Manufacture": "MSDInternationalGmbH",
    "Insurance": "自费",
    "Price": "980元/瓶",
    "Method": "口服",
    "Dose": "1次/日，1片/次（详见药品说明书）",
    "Association": "应与其他抗逆转录病毒药物联合使用",
    "WithFood": "可与或不与食物同服（详见药品说明书）"
  },
  {
    "Id": 12,
    "Name": "依非韦伦片/施多宁",
    "ProductName": "施多宁",
    "EnglishName": "Efavirenz",
    "Size": "600mg",
    "Type": "薄膜衣片",
    "Unit": "瓶",
    "Manufacture": "Merck Sharp&Dohme（Australia）Pty.Ltd",
    "Insurance": "自费（仿制药国家免费）",
    "Price": "515.4元/瓶（医保报销前）",
    "Method": "口服",
    "Dose": "1次/日，1片/次（详见药品说明书）",
    "Association": "应与其他抗逆转录病毒药物联合使用",
    "WithFood": "可与或不与食物同服（详见药品说明书）"
  },
  {
    "Id": 13,
    "Name": "洛匹那韦利托那韦片/克力芝",
    "ProductName": "克力芝",
    "EnglishName": "Lopinavir and Ritonavir Tablets",
    "Size": "200mg（洛匹那韦）:50mg（利托那韦）",
    "Type": "薄膜衣片",
    "Unit": "盒",
    "Manufacture": "AbbVie Deutschland GmbH & Co.KG",
    "Insurance": "国家免费",
    "Price": "615元/瓶",
    "Method": "口服",
    "Dose": "2次/日，2片/次（详见药品说明书）",
    "Association": "应与其他抗逆转录病毒药物联合使用",
    "WithFood": "可与或不与食物同服（详见药品说明书）"
  }
]

export function getInstruction() {
  return instruction;
}