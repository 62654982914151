import home_bg from "../images/home_bg.svg";

export default function HomeHeader() {
  return (
    <header className="header">
      <div className="header--title">
          <div className="header--title--main">艾算包</div>
          <div className="header--title--sub">为勇敢生活的您...</div>
      </div>
      <div className="header--image">
        <img src={home_bg} />
      </div>
    </header>
  )
}
