let cities = [
  {
    value: '广东',
    label: '广东',
    children: [
      {
        value: '广州',
        label: '广州',
      },
    ],
  },
  {
    value: '湖北',
    label: '湖北',
    children: [
      {
        value: '武汉',
        label: '武汉',
      },
    ],
  },
];

export function getCities() {
  return cities;
}