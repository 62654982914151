import { CheckList, Popup } from "antd-mobile";
import { useState } from "react";
import { DownCircleFilled } from "@ant-design/icons";

export default function PillPicker({ options, changeHandler}) {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState([]);

  const closePopup = () => setVisible(false)
  const clearAll = () => setValue([]);

  return (
    <>
      <div className="form-label">
        <div>选择抗逆转录病毒药物（可多选）</div>
        <DownCircleFilled
          style={{fontSize: "24px"}}
          onClick={() => {
            setVisible(true)
          }}
        />
      </div>
      <div className="form-field" onClick={() => {
          setVisible(true)
        }}
      >
        {value.length === 0 ?
          <div>请选择抗逆转录病毒药物</div> :
          value.map(item => (
            <div key={item} className="pill-list">{item}</div>
          ))}
      </div>
      <Popup
        visible={visible}
        onMaskClick={closePopup}
        bodyStyle={{ 
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          }}
      >
        <div className="popup-bar">
          <div className="popup-cancel" onClick={clearAll}>清除</div>
          <div className="popup-title">请选择抗逆转录病毒药物</div>
          <div className="popup-confirm" onClick={closePopup}>确定</div>
        </div>
        <CheckList multiple
          value={value}
          onChange={(value) => {
            setValue(value);
            changeHandler(value);
          }}
          style={{
            height:"300px",
            overflowY: "scroll" 
          }}
        >
          {options.map(pill => (
              <CheckList.Item 
                key={pill.name} 
                value={pill.name}>
                  {pill.name}
              </CheckList.Item>
            ))}
        </CheckList>
      </Popup>
    </>
  )
}