import { TagOutlined, CoffeeOutlined, InfoCircleOutlined } from "@ant-design/icons"

export default function PillInfo({ pill }) {
  return (
    <>
      <div className="pill-title">{pill.Name}</div>

      <div className="card-container">
        <div className="card-left">
          <TagOutlined style={{ fontSize: "16px" }}/>
          <div>基本信息</div>
        </div>
        <div className="card-right">
          <ul>
            <li><span>商品名: </span>{pill.ProductName}</li>
            <li><span>英文名: </span>{pill.EnglishName}</li>
            <li><span>规格: </span>{pill.Size}</li>
            <li><span>剂型: </span>{pill.Type}</li>
            <li><span>包装单位: </span>{pill.Unit}</li>
            <li><span>生产厂商: </span>{pill.Manufacture}</li>
          </ul>
        </div>
      </div>

      <div className="card-container">
        <div className="card-left">
          <CoffeeOutlined style={{ fontSize: "16px" }}/>
          <div>服用方法</div>
        </div>
        <div className="card-right">
          <ul>
            <li><span>服用方法: </span>{pill.Method}</li>
            <li><span>服用剂量: </span>{pill.Dose}</li>
            <li><span>联合用药: </span>{pill.Association}</li>
            <li><span>是否与食物同服: </span>{pill.WithFood}</li>
          </ul>
        </div>
      </div>

      <div className="card-container">
        <div className="card-left">
        <InfoCircleOutlined style={{ fontSize: "16px" }}/>
          <div>医保情况</div>
        </div>
        <div className="card-right">
          <ul>
            <li><span>是否医保: </span>{pill.Insurance}</li>
            <li><span>参考价格: </span>{pill.Price}</li>
          </ul>
        </div>
      </div>

      <div className="instruction-footer">
        <span>备注：</span>
        <div>1. 药品规格、剂型、生产企业和参考价格来源于第三方网站公开信息
        </div>
        <div>2. 药品服用方法、服用剂量和联合用药情况仅供参考，详情查看药品说明书
        </div>
      </div>
    </>
  )
}
