import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CookiesProvider, useCookies } from 'react-cookie';

import Footer from "../components/footer";

import { getPillData } from "../data/pillData";
import { getCities } from "../data/cities";
import { getPills } from "../data/pills";

import CalcHeader from "../components/calc_header";
import calc_bg from "../images/calc_bg.svg";
import CityPicker from "../components/city_picker";
import FacilityPicker from "../components/facility_picker";
import PillPicker from "../components/pill_picker";
import TypePicker from "../components/type_picker";

export default function Calculator() {
  const [cookies, setCookie] = useCookies(["pills"]);

  const pillData = getPillData();
  const cities = getCities();
  const pillList = getPills();
  const typeOptions = [["城镇职工医保", "居民医保"]];

  const navigate = useNavigate();

  const [city, setCity] = useState("请选择省份/城市");
  const [facilities, setFacilities] = useState([[]]);
  const [facility, setFacility] = useState("");
  const [pills, setPills] = useState([]);
  const [type, setType] = useState("");

  const [error, setError] = useState("");

  const cityChangeHandler = (value) => {
    // 0 is 省, 1 is 市级
    const cityValue = value[1];
    const data = pillData.filter(item => item.City === cityValue);

    let array = [];
    data.forEach(item => {
      if (!array.includes(item.Hospital)) {
        array.push(item.Hospital);
      }
    });
    setCity(cityValue);
    setFacilities([array]);
  }

  const facilityChangeHandler = (value) => {
    setFacility(value);
  }

  const pillChangeHandler = (value) => {
    setPills(value);
  }

  const typeChangeHandler = (value) => {
    setType(value);
  }

  const submitHandler = (e) => {
    e.preventDefault();
    if (city === "" || facility === "" || pills.length === 0 || type === "") {
      setError("每项必选");
      return;
    }

    setCookie("pills", pills, { path: "/" });
    navigate(`/caculate-result?city=${city}&facility=${facility}&pills=${JSON.stringify(pills)}&type=${type}`);
  }

  return (
    <div className="container">      
      <CookiesProvider>
        <CalcHeader 
          text1="艾算包"
          text2="医保计算" 
          image={calc_bg}
        />
        <div className="curve-container">
          <div className="curve" />
        </div>

        <main>
          <form className="calc-form">
            <CityPicker 
              options={cities}
              changeHandler={cityChangeHandler}
            />
            <FacilityPicker 
              options={facilities}
              changeHandler={facilityChangeHandler}
            />
            <PillPicker 
              options={pillList}
              changeHandler={pillChangeHandler}
            />
            <TypePicker
              options={typeOptions}
              changeHandler={typeChangeHandler}
            />

            <div className="error">{error}</div>
            <div className="submit-field" onClick={submitHandler}>
              <div className="button-text">开始计算</div>
            </div>
          </form>

          <div className="calc-footer">
            <span>友情提示：</span>
            本工具算法系制作方了解的相关地区HIV抗逆转录病毒药物的医保政策生成，结果仅供参考。如与当地实际执行政策不同，请以当地政策为准。
          </div>

        </main>
        <Footer active="calculator"/>
      </CookiesProvider>
    </div>
  )
}