let pills = [
  {
    name: "艾诺韦林片/艾邦德",
  },
  {
    name: "艾考恩丙替片/捷扶康",
  },
  {
    name: "比克恩丙诺片/必妥维",
  },
  {
    name: "多替阿巴拉米片/绥美凯",
  },
  {
    name: "拉米夫定多替拉韦片/多伟托",
  },
  {
    name: "多拉米替片/德思卓",
  },
  {
    name: "利匹韦林片/恩临",
  },
  {
    name: "多替拉韦钠片/特威凯",
  },
  {
    name: "拉替拉韦钾片/艾生特",
  },
  {
    name: "达芦那韦考比司他片/普泽力",
  },
  {
    name: "多拉韦林片/沛卓",
  },
  {
    name: "依非韦伦片/施多宁",
  },
  {
    name: "洛匹那韦利托那韦片/克力芝",
  },
]

export function getPills() {
  return pills;
}