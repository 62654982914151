import { Link } from "react-router-dom";
import { CalculatorFilled, MedicineBoxFilled } from "@ant-design/icons";

export default function HomeContent() {
  return (
    <main className="home-content">
      <Link to="/calculator">
        <div className="home-card">
          <div className="home-card--circle">
            <div className="home-card-icon-parent">
              <CalculatorFilled style={{ fontSize: "52px", color: "#FFFFFF" }} />
              <div className="home-card-icon-children">
                <CalculatorFilled style={{ fontSize: "42px", color: "#0068B7" }} />
              </div>
            </div>
          </div>
          <div>
            <div className="home-card--text--main">医保计算</div>
            <div className="home-card--text--sub">根据各地区的医保政策，算一算服用HIV抗逆转录病毒药物月度和年度花费，自己的经济账本不含糊</div>
          </div>
        </div>
      </Link>
      <Link to="/instruction">
        <div className="home-card">
          <div className="home-card--circle">
            <div className="home-card-icon-parent">
              <MedicineBoxFilled style={{ fontSize: "52px", color: "#FFFFFF" }} />
              <div className="home-card-icon-children">
                <MedicineBoxFilled style={{ fontSize: "42px", color: "#0068B7" }} />
              </div>
            </div>
          </div>
          <div>
            <div className="home-card--text--main">服药信息</div>
            <div className="home-card--text--sub">查询HIV抗逆转录病毒药物的基本信息、服用方法和医保情况，动动手指查一查更安心</div>
          </div>
        </div>
      </Link>
    </main>
  )
}
