import { Link } from "react-router-dom";
import { 
  HomeFilled,
  HomeOutlined,
  CalculatorFilled,
  CalculatorOutlined,
  MedicineBoxFilled,
  MedicineBoxOutlined
} from "@ant-design/icons";

export default function Footer({ active }) {
  const style = {
    color: "#0068B7",
    fontSize: "28px"
  }
  return (
    <footer>
      <div className="menubar">
        <Link to="/">
          {
            active === "home" ?
            <HomeFilled style={style}/> :
            <HomeOutlined style={style}></HomeOutlined>
          }   
        </Link>
        <Link to="/calculator">
          {
            active === "calculator" ?
            <CalculatorFilled style={style}/>
            :
            <CalculatorOutlined style={style}/>
          } 
        </Link>
        <Link to="/instruction">
          {
            active === "instruction" ?
            <MedicineBoxFilled style={style}/>
            :
            <MedicineBoxOutlined style={style}/>
          } 
        </Link>
      </div>
    </footer>
  )
}