import './App.css';
import Footer from './components/footer';
import HomeHeader from './components/home_header';
import HomeContent from './components/home_content';

function App() {
  return (
    <div className="container">
      <HomeHeader />
      <HomeContent />
      <Footer active="home"/>
    </div>
  );
}

export default App;
