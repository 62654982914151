import image1 from "../images/pills/1.png";
import image2 from "../images/pills/2.png";
import image3 from "../images/pills/3.png";
import image4 from "../images/pills/4.png";
import image5 from "../images/pills/5.png";
import image6 from "../images/pills/6.png";
import image7 from "../images/pills/7.png";
import image8 from "../images/pills/8.png";
import image9 from "../images/pills/9.png";
import image10 from "../images/pills/10.png";
import image11 from "../images/pills/11.png";
import image12 from "../images/pills/12.png";
import image13 from "../images/pills/13.png";

import { RightCircleFilled } from "@ant-design/icons";
import { useState } from "react";

export default function ImageList({ selected, list, imageClickHandler, resetList }) {
  const imageVars = [image1, image2, image3, image4, image5,
    image6, image7, image8, image9, image10, image11, image12, image13];

  const [showIcon, setShowIcon] = useState(true);

  const moreClickHandler = () => {
    resetList();
    setShowIcon(false);
  }

  return (
    <div className="image-container" >
      {list.map(pill => (
        <div             
          key={pill.Id} 
          className="pill-item" 
          onClick={() => imageClickHandler(pill)}>
          <img
            className={selected.Id === pill.Id ? "pill-image-border" : "pill-image"}
            width={70}
            height={70}
            src={imageVars[pill.Id-1]} />
          <div className="pill-name">{pill.Name}</div>
        </div>
      ))}
      {showIcon && <div className="more-container" onClick={moreClickHandler}>
        <RightCircleFilled 
          className="more-icon"
          style={{fontSize: "70px", color: "#cce1f1"}}
        />
        <div className="more-text">点击查看<br />更多药品</div>
      </div>}
    </div>
  )
}