import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import 'antd/dist/antd.css';
import './index.css';
import App from './App';
import Calculator from './pages/calculator';
import Instruction from './pages/instruction';
import CalculateResult from './pages/calculate_result';

import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />        
        <Route path="/calculator" element={<Calculator />} />
        <Route path="/instruction" element={<Instruction />} />
        <Route path="/caculate-result" element={<CalculateResult />} />
        <Route path="*" element={<App />}/>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
