import InstructHeader from "../components/instruct_header";
import Footer from "../components/footer";
import { CookiesProvider, useCookies } from 'react-cookie';
import instruct_bg from "../images/instruct_bg.svg";
import { getInstruction } from "../data/pill_instruction";
import ImageList from "../components/image_list";
import PillInfo from "../components/pill_info";
import { useEffect, useState } from "react";

export default function Instruction() {
  const initialList = getInstruction();
  const [instructionList, setInstructionList] = useState(initialList);

  const [cookies, setCookie] = useCookies(['pills']);
  const pills = cookies.pills;

  useEffect(() => {
    if (pills !== undefined) {
      const list = instructionList.filter(pill => pills.includes(pill.Name));
      setInstructionList(list);
      setSelected(list[0]);
    }
  }, []);

  const [selected, setSelected] = useState(instructionList[0]);

  return (
    <div className="container">      
      <CookiesProvider>
        <InstructHeader 
          text1="艾算包"
          text2="服药信息"
          image={instruct_bg}
        />
        <div className="curve-container">
          <div className="instruct-curve">
            <div className="instruct-title">选择查询药品</div>
          </div>
        </div>
        <main>
          <ImageList 
            selected={selected} 
            list={instructionList} 
            imageClickHandler={setSelected}
            resetList={() => setInstructionList(initialList)}
          />
          <PillInfo pill={selected}/>
        </main>
        <Footer active="instruction"/>
      </CookiesProvider>
    </div>
  )
}